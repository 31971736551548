<template>
    <div id="app">
        <div class="main">
            <BaiduMap4 />
            <!-- <RightBox4 /> -->
        </div>
    </div>
</template>
  
<script>
import BaiduMap4 from './BaiduMap4.vue';
// import RightBox4 from './RightBox4';

export default {
    components: {
        BaiduMap4,
        // RightBox4,
    },
    mounted() {
    },
    methods: {
    }
}
</script>
  
<style></style>