<template>
    <div id="app">
      <div class="main">
          <div class="content">
              <BaiduMap3 />
          </div>
      </div>
    </div>
  </template>
  
  <script>

  import BaiduMap3 from './BaiduMap3.vue';
  export default {
    components: {
      BaiduMap3,
    },
    mounted() {
    },
    methods: {
    }
  }
  </script>
  
  <style>

  </style>